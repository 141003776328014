<template>
	<div class="show-more-container" @mouseenter="hovering = true" @mouseleave="hovering = false">
		<div :class="['text', hidden ? 'hide' : '']" v-html="excerpt"></div>
		<div class="button" @click="showMore" v-if="isTextLong">
			<transition name="arrow-slide">
				<i :class="[iconClass, { 'hovering': hovering }]"></i>
			</transition>
			<transition name="text-slide">
				<span v-show="hovering">{{(hidden ? $t('general.showMore') : $t('general.showLess'))}}</span>
			</transition>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			'text': {
				type: String,
			},
			'maxWords': {
				type: Number,
				default: 200
			}
		},
		data: () => ({
			hidden: true,
			hovering: false
		}),
		methods: {
			showMore() {
				this.hidden = !this.hidden;
			}
		},
		computed: {
			iconClass() {
				return this.hidden ? 'el-icon-caret-bottom' : 'el-icon-caret-top';
			},
			excerpt() {
				return this.isTextLong && this.hidden ? this.transformedText.substring(0, 130) + '...' : this.transformedText;
			},
			transformedText() {
				return window.unescape(this.text);
			},
			isTextLong() {
				return this.transformedText.length > 130;
			}
		}
	};
</script>

<style scoped lang="scss">
	.show-more-container {
		border: 1px solid #ebeef5;
		border-radius: 4px;

		.text {
			transition: .2s;
		}

		.button {
			color: #d3dce6;
			text-align: center;
			margin-top: 5px;
			padding: 5px 0;
			cursor: pointer;
			border-top: 1px solid #ebeef5;

			&:hover {
				color: #ff601d;
			}

			i {
				font-size: 16px;
				transition: .3s;

				&.hovering {
					transform: translateX(-40px);
				}
			}

			>span {
				position: absolute;
				transform: translateX(-30px);
				font-size: 13px;
				transition: .3s;
				display: inline-block;
				margin-top: 2px;
			}
		}

		.text {
			font-size: 13px;
			padding: 10px;
			word-break: break-word;
			color: #666;
			line-height: 1.4;
		}
	}

	.text-slide-enter,
	.text-slide-leave-active {
		opacity: 0;
		transform: translateX(10px);
	}
</style>